.component-sommes-nous {
}
.bakeli-sch {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  display: flex;
  align-items: center;
  color: #009688;
}
.bakeli-sch:hover {
  background: linear-gradient(to right, #009688, #f44336, #ff9800);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.txt-som-nous {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 36px;
  line-height: 27px;
  align-items: center;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
}
.txt-som-nous:hover {
  color: #ff9800;
}
.mots-bakeli {
  font-family: "Montserrat";
  font-style: normal;
  font-size: 16px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #000000;
}
.depl-images-animation {
  background-image: url(../../img/pana.png);
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -30px;
  width: 370px;
  height: 370px;
}
.depl-images-animation:hover {
  background-image: url(../../img/questionDeux.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 370px;
  height: 370px;
  overflow: hidden;
  animation: switch 5s infinite;
  background-repeat: repeat-n;
  -webkit-animation: switch 5s infinite;
}
@keyframes switch {
  0% {
    background-image: url(../../img/questions.png);
  }
  20% {
    background-image: url(../../img/questionDeux.png);
  }
  60% {
    background-image: url(../../img/questions.png);
  }
  100% {
    background-image: url(../../img/pana.png);
  }
}

@media only screen and (max-width: 960px) {
  .depl-images-animation {
    width: 170px;
    height: 170px;
  }
}
