.postuler-stagiaire{
    margin-bottom: 15px;
    border: none;
}
.born-day{
    margin-left: 13%;
}
.couleur-icons-profil{
    color: grey;
}
.txt-input{
	border: none;
	background: transparent;
	color: #fff;
	text-decoration: none;
	outline: none;
	border-bottom: 1px solid #c4c4c4;
	font-size: 15px;
	height: 40px;
    width: 100%;
}
.txt-input:hover{
    text-decoration: none;   
    outline: none;
}
.largeur-edit-icon{
    padding-right: 0px;
    padding-left: 0px;
}
.largeur-edit-icon img{
   margin-left: 15%;
   cursor: pointer;
}