.avatar-coach-dashboard-bakeliste{
    width: 45px;
    height: 45px;
    margin-right: 10px;
    border-radius: 50%;
}
.item-coach-dashboard-bakeliste{
    background: #FFFFFF;
    border-radius: 5px;
    padding: 15px 15px 15px 7px;
    display: flex;
    width: 100%;
}
.name-coach-dashboard-bakeliste{
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #069A77;
}
.function-coach-dashboard-bakeliste{
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.7);
}
.show-details-coach-dashboard-bakeliste{
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #069A77;
    transition: .2s ease-in-out;
    background-color: transparent;
    padding: 0;
    border: 0;
}
.modal-details-coach{
    padding: 2rem 3rem 6rem;
    background-color: #ffffff;
    border: 0;
    border-radius: 0;
}
.avatar-details-coach-dashboard-bakeliste{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
}
.name-details-coach-dashboard-bakeliste{
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #069A77;
}
.email-details-coach-dashboard-bakeliste{
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.7);
}
.h-info-details-coach-dashboard-bakeliste{
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.7);
}
.info-details-coach-dashboard-bakeliste{
    font-size: 18px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 700;
}
