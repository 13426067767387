
.component-centre-interet{
    background-color: #fff;
    
}
.contain-img-cm-marche{
    width: 64%;
    height: 240px;
}
.contain-img-cm-marche:hover{
    border-radius: 50%!important;
    border: 0.5px solid #009688;
    box-sizing: border-box;
    filter: blur(0);
    text-decoration: line-through;
    text-overflow: initial;
    box-shadow: 0px 1px 1px 0px #009688;
}
.title-cm-marche{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
}
.coaching-txt {
    text-align: center;
    font-weight: bold;
    font-size: 36px;
    color: #FF9800;
}
.coaching-txt:hover{
    color: #009688;
}
@media only screen and (max-width: 960px){
    .coaching-txt {
        text-align: center;
        font-weight: bold;
        font-size: 20px;
        color: #FF9800;
    }
}