.logo-header{
    width: 80px;
}
.avatar-rs-custom{
    margin-right: 15px;
    background: rgb(6, 154, 119);
}
.welcome-header{
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.055em;
    color: #069A77;
}
.annee-header{
    border-left: 2px solid #C4C4C4;
    margin-left: 15px;
    padding-left: 15px;
    margin-top: 0;
    display: flex;
    align-items: center;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.07em;
    color: #035540;
}
.name-avatar-custom{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #011F3B;
}
.navbar-light .navbar-toggler {
    border-color: transparent;
}

.navbar-light .navbar-toggler-icon {
    background-image: url(../../img/icons/menu.svg);
}
.height-header{
    /* height: 100px; */
}
.icon-pointage{
    width: 20px;
    margin-right: 15px;
}

.dropdown-item-header-dashboard{
    display: flex;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    outline: 0 !important;
    white-space: nowrap;
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 0;
    text-decoration: none;
    transition: .2s ease-in-out;
    color: #858585;
}
.dropdown-item-header-dashboard:hover{
    transition: .2s ease-in-out;
    text-decoration: none;
    background-color: #069A77;
    color: #ffffff;
}