.component-contenu-liste {
}

@import "https://s3-us-west-2.amazonaws.com/s.cdpn.io/t-97/variables.less";
@import "https://s3-us-west-2.amazonaws.com/s.cdpn.io/169963/mixins.less";
body {
  background: #f2f2f2;
  font-family: "proxima-nova-soft", sans-serif;
  font-size: 14px;
}


.radiobtn1 {
  position: relative;
  display: block;
}
.radiobtn1 label {
  display: block;
  background: #fee8c3;
  color: #444;
  border-radius: 5px;
  padding: 10px 20px;
  border: 2px solid #fdd591;
  margin-bottom: 5px;
  cursor: pointer;
}
.radiobtn1 label:after,
.radiobtn1 label:before {
  content: "";
  position: absolute;
  right: 11px;
  top: 11px;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background: #fdcb77;
}
.radiobtn1 label:before {
  background: transparent;
  transition: 0.1s width cubic-bezier(0.075, 0.82, 0.165, 1) 0s,
    0.3s height cubic-bezier(0.075, 0.82, 0.165, 2) 0.1s;
  z-index: 2;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 13px;
  background-position: center;
  width: 0;
  height: 0;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNS4zIDEzLjIiPiAgPHBhdGggZmlsbD0iI2ZmZiIgZD0iTTE0LjcuOGwtLjQtLjRhMS43IDEuNyAwIDAgMC0yLjMuMUw1LjIgOC4yIDMgNi40YTEuNyAxLjcgMCAwIDAtMi4zLjFMLjQgN2ExLjcgMS43IDAgMCAwIC4xIDIuM2wzLjggMy41YTEuNyAxLjcgMCAwIDAgMi40LS4xTDE1IDMuMWExLjcgMS43IDAgMCAwLS4yLTIuM3oiIGRhdGEtbmFtZT0iUGZhZCA0Ii8+PC9zdmc+);
}
.radiobtn1 input[type="radio"] {
  display: none;
  position: absolute;
  width: 100%;
  appearance: none;
}
.radiobtn1 input[type="radio"]:checked + label {
  background: #fdcb77;
  animation-name: blink1;
  animation-duration: 1s;
  border-color: #fcae2c;
}
.radiobtn1 input[type="radio"]:checked + label:after {
  background: #fcae2c;
}
.radiobtn1 input[type="radio"]:checked + label:before {
  width: 20px;
  height: 20px;
}
.post-module {
  position: relative;
  z-index: 1;
  display: block;
  background: white;
  min-width: 270px;
  height: 470px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
}
.post-module:hover .thumbnail img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
  opacity: 0.5;
}
.post-module .thumbnail {
  background: white;
  height: 400px;
  overflow: hidden;
}
.post-module .thumbnail .date {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
  background: #e74c3c;
  width: 55px;
  height: 55px;
  padding: 12.5px 0;
  color: white;
  font-weight: 700;
  text-align: center;
}
.post-module .thumbnail .date .day {
  font-size: 18px;
}
.post-module .thumbnail .date .month {
  font-size: 12px;
  text-transform: uppercase;
}
.post-module .thumbnail img {
  display: block;
  width: 120%;
}
.post-module .post-content {
  position: absolute;
  bottom: 0;
  background: white;
  width: 100%;
  /* padding: 30px; */
}
.post-module .post-content .category {
  position: absolute;
  top: -34px;
  left: 0;
  background: #e74c3c;
  padding: 10px 15px;
  color: white;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}
.post-module .post-content .title {
  margin: 0;
  padding: 0 0 10px;
  color: black;
  font-size: 18px;
  height: 24vh;
  line-height: 7vh;
  font-weight: 600;
}

.post-module .post-content .sub_title {
  margin: 0;
  padding: 0 0 20px;
  color: #e74c3c;
  font-size: 20px;
  font-weight: 400;
}
.post-module .post-content .description {
  color: #3f4142;
  font-size: 14px;
  line-height: 1.8em;
}
.post-module .post-content .post-meta {
  margin: 30px 0 0;
  color: #ff9800;
}
.post-module .post-content .post-meta .timestamp {
  margin: 0 16px 0 0;
}
.post-module .post-content .post-meta a {
  color: bisque;
  text-decoration: none;
}
.hover .post-module:hover all .post-content .description {
  display: block !important;
  height: auto !important;
  opacity: 1 !important;
}

.container-content:before,
.container-content:after {
  content: "";
  display: block;
  clear: both;
}
.container .column {
  width: 50%;
  padding: 0 25px;
  float: left;
}
.container .column .demo-title {
  margin: 0 0 15px;
  color: black;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}
.info-content {
  width: 300px;
  margin: 50px auto;
  text-align: center;
}
.info-content h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 24px;
  font-weight: bold;
  color: black;
}
.info-content span {
  color: black;
  font-size: 18px;
}
.container .info span a {
  color: violet;
  text-decoration: none;
}
.info-content span .fa {
  color: #e74c3c;
}
.row .content-block {
  width: 100%;
  overflow-y: hidden;
}
