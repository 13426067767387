.component-header {
    
}

a i {
    color: gray !important;

}
.lien {
    color: gray !important;
}
.Logo {
    background-image: url(../../img/logo.png);
  }
  .img {
    width: 88px;
    height: 50px;
    margin-left: 84px;
    top: 30px;
  }
  .text-theme {
    color: #009688;
    font-size:1.5rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
  }
  .ss {
      width: 100%;
  }
  .fas {
      font-size: 1.2rem;
  }
  .border {
      border: 2px #009688 solid;
      border-radius: 25%;
      float: left;
      margin-left: 2rem;
  }
  .form-control-costom {
        border: none;
        border-radius: 10px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        color: rgba(0, 0, 0, 0.7);
        border-radius: 10px;
  }
  .iconInput {
    border: 0.5px solid #069A77;
    box-sizing: border-box;
    border-radius: 10px;
    padding-right: 10px;

  }
  .btn-costum {
      color: gray;
      background-color: white;
  }



  /* At the top level of your code */


  @media screen and (max-width: 768px) {
      #dropdownMenuButton {
          z-index: 1;
          float: right;
          margin-top: 1rem;
      }
  }



  @media screen and (min-width: 992px) and (max-width: 1200px) {
    .essaie {
        padding: 0;
        margin: 0;
        font-size: 1rem;
        display: flex;
        padding-top: 1rem;
        white-space: nowrap;
    }
    .img {
        margin-left: 30px;
        top: 30px;
    }
    .input {
        margin-left: 3rem;
    }
  }


