
.component-webinar{
    
}


.tps {
    letter-spacing: 0.1rem;
    font-weight: bold;
    font-family: 'Montserrat' !important;
    font-size: 1rem;
    color:  #009688;
    text-align: center;
}
.right_sidebar_card {
    width: 100%;
    padding: 2px;
    background: #FFFFFF;
    border-radius: 5px;
}
.btn-participer {
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(112, 112, 112, 0.1);
    border-radius: 10px;
    width: 100%;
    padding: 0.7rem;
    border: 1px solid #f3f3f3;
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.75) !important;
    text-align: center;
}
.btn-participer:hover {
    text-decoration: none;
}
.webinar {
    margin-left: 5px;
    font-size: 0.9rem;
}

.image-zoom {
    width: 28px;
    height: 28px;
    margin-right: 1rem;
}
.button-zoom {
    padding-left: 7rem;
}