
.component-content-modal{
    
}
.btn-modal {
    color: #009688;
    font-size:1rem;
    font-family: 'Montserrat', sans-serif;
}
.bouton-modal {
    text-align: right;
}