.component-adresse {
}
.right_sidebar_card {
  width: 100%;
}

.line {
  display: inline;
}

.loc {
  color: rgb(0, 150, 136);
  font-size: 3rem;
}
.texte {
  color: #009688;
  font-size: 11px;
  font-family: "Montserrat" !important;
  font-weight: bold;
  font-style: normal;
  color: #069a77;
  margin-left: 5%;
}
.espace {
  padding-left: 2rem;
  font-weight: bold;
  font-size: 0.8rem;
  color: rgb(0, 150, 136);
}
.theme {
  font-size: 0.8rem;
  color: #009688 !important;
  text-align: right !important;
  white-space: nowrap;
  text-decoration: none;
}
.theme:hover {
  text-decoration: none;
}
.map-texte {
  text-align: right;
  padding-right: 1.5rem;
}

/* @media screen and(max-width: 1200) and (min-width: 1300) {
    .theme {
        width: auto;
        max-width: 100%;
        flex-direction: column;
    }
} */
