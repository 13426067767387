.component-button {
}

.btn-rejoindre {
  font-family: "Montserrat", sans-serif;
  background-color: white;
  color: #009688;
  border-radius: 0.5rem;
  border: 1px solid #009688;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  width: 100%;
  letter-spacing: 0.1rem;
  font-style: normal;
  padding: 1rem;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}

.btn-rejoindre:hover {
  background-color: #009688;
  color: white;
}

.btn-green {
  font-family: "Montserrat", sans-serif;
  background-color: #009688;
  color: white;
  border-radius: 0.5rem;
  border: 1px solid #009688;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  width: 100%;
  letter-spacing: 0.1rem;
  font-style: normal;
  padding: 1rem;
  box-sizing: border-box;
}
.btn-green:hover {
  background-color: white;
  color: #009688;
}
