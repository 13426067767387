
.component-card-rentree{
    
}


.ajuster {
    display: inline-block;
    margin-left: 1rem;
    font-size: 0.7rem;
}

.tpss {
    line-height: 96%;
    font-weight: bold;
    font-family: 'Montserrat';
    font-size: 16px;
    color:  #009688 !important;
    text-align: center;
}
.titre {
    font-family: 'Montserrat';
    font-size: 1rem;
    color:  #009688;
    margin-bottom: 0.2rem;
}

.btn-reserver {
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.75);
    width: 100%;
    padding: 1rem;
}
.datee {
    width: 100%;
    height: 12rem;
}