.video-title{ 
    color: black !important;
}

.largeur a {
    color: rgb(3, 148, 245);
}

.poster > div div svg {
    font-size: 1rem;
}
/*  */

@media screen and (max-width: 768px) {
    .videos {
        /* width: 100%; */
        /* padding-left: 2rem;
        padding-right: 2rem; */
    }
    
}

@media screen and (min-width: 768) and (max-width: 991px) {
    .one___flkjsjJJNJnn_nANN8hG_YG7GY7g7BH9 {
        width: 220px;
        
        height: 300px;
        color: #006eff;
        background: black;
        position: relative;
    }
    
    .one___flkjsjJJNJnn_nANN8hG_YG7GY7g7BH9 > video {
        width: 220px;
        height: 100%;
        z-index: -1;
    }
    .poster > div img {
        width: 220px;
        height: 100%;
        margin: 0px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .videos {
        /* width: 250px;
        padding: 1rem;
        background-color: black; */
        /* flex-direction: column;
        padding-left: 2rem;
        padding-right: 2rem;
        display: flex; */
    }

    .one___flkjsjJJNJnn_nANN8hG_YG7GY7g7BH9 {
        width: 260px;
        
        height: 300px;
        color: #006eff;
        background: black;
        position: relative;
    }
    
    .one___flkjsjJJNJnn_nANN8hG_YG7GY7g7BH9 > video {
        width: 250px;
        height: 100%;
        z-index: -1;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1480px) {
    .one___flkjsjJJNJnn_nANN8hG_YG7GY7g7BH9 {
        width: 250px;
        height: 130px;
        color: #006eff;
        position: relative;
    }
    
    .one___flkjsjJJNJnn_nANN8hG_YG7GY7g7BH9 > video {
        width: 220px;
        height: 100%;
        z-index: -1;
    }
}

@media screen and (min-width: 1481px) and (max-width: 1760px) {
    .one___flkjsjJJNJnn_nANN8hG_YG7GY7g7BH9 {
        width: 200px;
        height: 200px;
        color: #006eff;
        /* background: black; */
        position: relative;
    }
    
    .one___flkjsjJJNJnn_nANN8hG_YG7GY7g7BH9 > video {
        width: 200px;
        height: 100%;
        z-index: -1;
    }
}