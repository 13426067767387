.component-content-event-recent {
}

.contenu_texte {
  width: 100%;
}
p {
  min-width: (300px, 50%, 20rem);
}
.coder {
  font-family: "Montserrat";
  font-size: 1rem;
  color: #009688;
  margin-bottom: 0.2rem;
}
.descrip {
  font-size: 1rem;
  font-family: "Montserrat";
}
.cvvv {
  font-size: 0.8rem;
  font-family: "Montserrat";
}
.btn-voir {
  font-family: "Montserrat", sans-serif;
  background-color: #009688;
  color: white;
  border-radius: 0.5rem;
  border: 1px solid #009688;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 1rem;
  font-weight: bold;
  font-size: 14px;
}

.btn-voir:hover {
  background-color: white;
  color: #009688;
}
.dd {
  color: #085550;
}
.textt {
  font-size: 0.6rem;
}
.hh {
  font-weight: bold;
  font-family: "Montserrat";
}
