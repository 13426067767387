
.component-content-event{
    
}

.page-event {
    width: 100%;

}

.event-page {
    padding-right: 15px !important;
}

.skeleton-item {
    background-color: #D8D8D8;
    border-color: #D8D8D8;
    display: inline-block;
    position: relative;
    overflow: hidden;
}
.skeleton-item::after {
        content: '';
        position: absolute;
        top: -125%;
        left: -100%;
        width: 40px;
        height: 350%;
        opacity: 0;
        transform: rotate(45deg);
        background: rgba(255, 255, 255, 0.20);
        background: linear-gradient(to right, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0) 100%);
        animation: shine;
        animation-duration: 4s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-out;
    
}

@keyframes shine {
    from {
        opacity: 0.5;
        left: -100%;
    }

    to {
        opacity: 1;
        left: 200%;
    }
}