.postuler-button-card-center{
    text-align: center;
}
#txt-designer-ui{
    text-align: center;
}
#Postuler-buton-sendeal{
    width: 55%;
    height: 45px;
    background: #009688;
    border-radius: 10px;
    text-decoration: none;
    border: none;
    outline: none;
    margin-top: 20px;
    margin-bottom: 45px;
}
.offre-stage-card-bordure-non{
    background: rgba(196, 196, 196, 0.05);
    border: none;
}
.btom-card-offre-stage-bord{
    border: 0.1em solid #009688;
}
.midle-center-card-left{
    text-align: center;
    margin-top: 25px;
    margin-bottom: 15px;
}
.dimunition-img-reseaux{
    width: 35px;
    height: 20px;
    cursor: pointer;
}