.component-content-event-kokutana {
}

.imlogo {
  width: 228px;
  height: 214px;
  display: flex;
  margin-left: 40%;
}
.kokutana {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: inset 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.marge {
  margin-left: 30%;
}
/* .ligne {
    
    border: 1px rgba(0, 150, 136, 0.6) solid;
} */
.btn-checkk {
  background-color: white;
  border: 1px #009688 solid;
  color: #009688;
  width: 100%;
  line-height: 2rem;
  border-radius: 1rem;
}
.btn-checkk:focus {
  background-color: #009688;
  color: white;
}
.textgreen {
  color: rgba(0, 150, 136, 0.6);
  font-weight: bold;
}

.btn-chiffre {
  background: rgba(0, 150, 136, 0.1);
  border-radius: 5px;
  width: 68px;
  height: 38px;
}
.bloctext {
  background: #ffffff;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.bloctext2 {
  background: #ffffff;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
}
.textgreen2 {
  color: #009688;
  font-weight: bold;
  text-align: center;
}
.tailletext {
  font-size: 0.8rem;
  text-align: center;
  margin-right: 2rem;
}
.taillebtn {
  border: none;
  float: right;
  margin-top: -2rem;
}
.btn-chiffre2 {
  background: rgba(0, 150, 136, 0.1);
  border-radius: 5px;
  width: 30px;
  height: 30px;
  color: #009688;
}
.btn-education {
  background: rgba(0, 150, 136, 0.1);
  border-radius: 5px;
  /* width: 30%; */
}
.texte_icon {
  padding-left: 5%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}
.texte_icon_titre {
  padding-left: 10%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}
.icon_tab_pan {
  color: rgba(0, 0, 0, 0.5);
}
.btn_btn {
  padding-left: 10%;
}
.enlever-bord{
  border: none;
}
