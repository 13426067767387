.link-aide{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #009688;
    transition: .2s ease-out;
    background-color: transparent;
}
.link-aide:hover{
    text-decoration: none;
    transition: .2s ease-out;
    color: #01645a;
}
.item-statistics-dashboard{
    background: #FFFFFF;
    border-radius: 10px;
    padding: 15px 20px;
    position: relative;
    width: 100%;
}
.designation-item-statistics-dashboard{
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
}
.chiffre-item-statistics-dashboard {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 55px;
    line-height: 60px;
    color: #009688;
}
.img-item-statistics-dashboard{
    width: 40px;
    position: absolute;
    top: 15px;
    right: 20px;
}

.img-item-statistics-dashboard.icon2{
    height: 30px;
    width: 25px;
}

.card-dashboard{
    padding: 15px 20px;
    background: #FFFFFF;
    border-radius: 5px;
}
.h-card-dashboard-bakeliste{
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
}
.icon-h-card-dashboard-bakeliste{
    width: 16px;
    margin-right: 15px;
}