
.component-content-event-jeemacoder{
    
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #009688;
    background-color: #fff;
    border-color: none;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border-color: none;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border-color: none;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #009688;
    background-color: #fff;
    border-color:none;
}

