
.component-view-accueil{
}


@media screen and (max-width: 1200px) {
    
    
    .main {
        width: auto;
        flex: none;
        max-width: 100%;
    }

    
    
}