.postuler-demande-cand-stage{
    border-top: 1.3em solid #009688;
}
#txt-volkeno-center{
    text-align: center;
    margin-top: 18px;
    margin-bottom: 18px;
}
.volkeno-bord-card-top{
    border: none;
    background: rgba(196, 196, 196, 0.05);
}
.volkeno-bord-card-top-2{
    background: rgba(196, 196, 196, 0.05);
    margin-top: 3%;   
}
.txt-orange{
    color: #FF9800;
}
.txt-input-2{
	border: none;
	background: transparent;
	color: #fff;
	text-decoration: none;
	outline: none;
	border-bottom: 1px solid #c4c4c4;
	font-size: 15px;
	height: 40px;
    width: 35%;
}
.txt-input-2:hover{
    text-decoration: none;   
}
#demande-postuler-buton{
    width: 25%;
    height: 45px;
    background: #009688;
    border-radius: 10px;
    text-decoration: none;
    border: none;
    outline: none;
    margin-top: 40px;
    margin-left: 10%;
    margin-bottom: 25px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1), 0 7px 20px 0 rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 360px){
    .txt-input-2{
        width: 100%;  
    }
}
@media screen and (max-width: 375px){
    .txt-input-2{
        width: 100%;  
    }
}
@media screen and (max-width: 414px){
    .txt-input-2{
        width: 100%;  
    }
}
@media screen and (max-width: 768px){
    .txt-input-2{
        width: 100%;  
    }
}
@media screen and (max-width: 1024px){
    .txt-input-2{
        width: 100%;  
    }
}