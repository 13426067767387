
.component-dialog-boite{
    
}
.dialogCloseButtonStyles{
    background-color: #fff;
    height: 50px;
    border: 2px solid #009688 ;
    color: #009688;
    width: 40%;
    line-height: 2rem;
    border-radius: 4rem;
    margin-bottom: 2rem;
    cursor: pointer;
    font-weight: bold;
    align-self: center;
  box-shadow: 0 5px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1) !important;

}
.dialogStyles {
    width: 550px;
    max-width: 100%;
    margin: 0 auto;
    position: fixed;
    left: 50%;
    top: 55%;
    transform: translate(-50%,-50%);
    z-index: 999;
    background-color: #fff;
    padding: 2px 2px 0px;
    border-radius: 10px;
    display: flex;
    border: 1px solid #009688;
    flex-direction: column;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1), 0 7px 20px 0 rgba(0, 0, 0, 0.1) !important;

};
.btn-inscrit-event{
    border: 2px solid #009688 ;
    color: #009688;
    width: 40%;
    line-height: 2rem;
    border-radius: 4rem;
    margin-bottom: 2rem;
  }
  .btn-inscrit-event:hover{
    background-color: #009688;
    color: #ffffff;
  }