
.carousel-slider-comp{
    background: #fff;
}
.react-multi-carousel-item {
    left: 5%;
}
.tail-txt-carousel{
    font-size: 1.5rem;
}
.center-txt-temoig{
    text-align: center;
    margin-bottom: 85px;
    padding-top: 25px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    color: rgba(0, 0, 0, 0.8);
}
.center-txt-temoig:hover{
    color: #FF9800;
}
.la-marge-de-la-photo{
    padding-left: 8%;
}
.dimunition-de-la-taille-img{
    width: 400px;
    height: 400px;
    margin-bottom: 90px;
}
.comportement-de-notre-text{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    line-height: 153.2%;
    padding-top: 7%;
    padding-left: 14%;
    padding-right: 10%;
    font-size: 18px;
    color: #000000;
}
.deplacement-title-responsable{
    padding-left: 14%;
    padding-top: 7%;
    font-style: normal;
    font-weight: 500;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.8);
}
.taille-but-slid{
    width: 34px;
    height: 10px;
    background: #C4C4C4;
    padding-right: 15px;
    margin: 4px;
}
