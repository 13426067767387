
.component-carousel{
    
}


.offrestage {
    background: #FFFFFF;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 10px 10px;
    
}