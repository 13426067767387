.component-inscription-masterclass {
}

.container-nav-vertical-home.masterclass {
  /* background: #009688; */

  /* width: 100%; */
  position: fixed;
  background: linear-gradient(
      90deg,
      rgba(8, 83, 78, 0.42) 35%,
      rgba(0, 150, 136, 0.08) 100%
    ),
    url(../../img/masterclass.jpeg);

  /* background-position: 50%; */
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1rem 3 1rem;
  /* max-width: 16.666667%; */
  width: 650px;
  margin-left: -1em;
  height: 700px;
}
