img.t-img-programmation{
    width: 100%;
    height: 250px;
    border-radius: 10px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1), 0 7px 10px 0 rgba(0, 0, 0, 0.1);
}
#color-li{
    margin-left: 10%;
}
.youtub{
   
    background: linear-gradient(
      90deg,
      rgba(8, 83, 78, 0.42) 35%,
      rgba(0, 150, 136, 0.08) 100%
    ),
    url(../../img/youtube.jpeg);
}

.btn-play{
    background: transparent !important;
    border-radius: 10px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.08em;
    color: red !important;
    padding: .8rem;
    transition: .2s ease-out;
    /* border: 1px solid red !important; */

}

.jjj{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-transition: opacity .25s cubic-bezier(0,0,0.2,1);
    transition: opacity .25s cubic-bezier(0,0,0.2,1);
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
}
.ytp-button {
    outline: 0;
}
.ytp-button {
    border: none;
    background-color: transparent;
    padding: 0;
    color: inherit;
    text-align: inherit;
    font-size: 100%;
    font-family: inherit;
    cursor: default;
    line-height: inherit;
}
.ytp-large-play-button {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 68px;
    height: 48px;
    margin-left: -18%;
    margin-top: -5%;
    -webkit-transition: opacity .25s cubic-bezier(0,0,0.2,1);
    transition: opacity .25s cubic-bezier(0,0,0.2,1);
    z-index: 64;
}
.container .columne {
    width: 20%;
    padding: 0 25px;
    float: left;
  }

.ytbe{
    text-align: center;
    /* margin-top: -60%; */
    z-index: 63;
    margin-left: -34px;
    margin-top: -80px;
}
.btn-play:hover{
    color: red !important;
    background: transparent !important;
    transition: .2s ease-in;
}
.player{
    width: 50%;
    height: 50%;
}
.dist-li{
    margin-bottom: 15px;
    color: #009688;
}
.txt-prog{
    color: black;
}
.dev-page {
    width: 100%;
}
.container .columne {
    width: 50%;
    padding: 0 50px;
    float: left;
  }
  .container .columne .demo-title {
    margin: 0 0 15px;
    color: black;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
  }
.btn-theme-light1{
    padding: 0.5rem 1rem;
    background: #ffffff;
    box-shadow: 0px 4px 15px rgb(0 0 0 / 10%);
    border-radius: 10px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 17px;
    
    align-items: center;
    text-align: center;
    letter-spacing: 0.065em;
    color: #069a77;
    border: 1px solid transparent;
    transition: 0.2s ease-in-out;
}
.btn-theme-light1:hover {
    background-color: #069a77;
    color: #ffffff;
    text-decoration: none;
    transition: 0.2s ease-in-out;
  }
  .btn-theme-light1:focus {
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    color: #069a77;
  }
  .modal-dialog {
    max-width: 800px;
    margin: 30px auto;
}

.modal-body {
position:relative;
padding:0px;
}
.btn-close {
position:absolute;
right:-30px;
top:0;
}

.post-moduleA {
    position: relative;
    z-index: 1;
    display: block;
    background: white;
    min-width: 300px;
    height: 270px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  }
  .post-moduleA:hover .thumbnail img {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 0.5;
  }
  .post-moduleA .thumbnail {
    background: white;
    height: 400px;
    overflow: hidden;
  }
  .post-moduleA .thumbnail .date {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
    background: #e74c3c;
    width: 55px;
    height: 55px;
    padding: 12.5px 0;
    color: white;
    font-weight: 700;
    text-align: center;
  }
  .post-moduleA .thumbnail .date .day {
    font-size: 18px;
  }
  .post-moduleA .thumbnail .date .month {
    font-size: 12px;
    text-transform: uppercase;
  }
  .post-moduleA .thumbnail img {
    display: block;
    width: 120%;
  }
  .post-moduleA .post-content {
    position: absolute;
    bottom: 0;
    background: white;
    width: 100%;
    /* padding: 30px; */
  }
  .post-moduleA .post-content .category {
    position: absolute;
    top: -34px;
    left: 0;
    background: #e74c3c;
    padding: 10px 15px;
    color: white;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .post-moduleA .post-content .title {
    margin: 0;
    padding: 0 0 10px;
    color: black;
    font-size: 18px;
    height: 24vh;
    line-height: 7vh;
    font-weight: 600;
  }
  
  .post-moduleA .post-content .sub_title {
    margin: 0;
    padding: 0 0 20px;
    color: #e74c3c;
    font-size: 20px;
    font-weight: 400;
  }
  .post-moduleA .post-content .description {
    color: #3f4142;
    font-size: 14px;
    line-height: 1.8em;
  }
  .post-moduleA .post-content .post-meta {
    margin: 30px 0 0;
    color: #ff9800;
  }
  .post-moduleA .post-content .post-meta .timestamp {
    margin: 0 16px 0 0;
  }
  .post-moduleA .post-content .post-meta a {
    color: bisque;
    text-decoration: none;
  }
  .hover .post-moduleA:hover all .post-content .description {
    display: block !important;
    height: auto !important;
    opacity: 1 !important;
  }
  