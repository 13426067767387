
.arrow-left-accompagner {
    width: 100%;
    height: 154px;
    clip-path: polygon(7% 0, 100% 0%, 100% 100%, 7% 100%, 0 49%);
    -webkit-clip-path: polygon(7% 0, 100% 0%, 100% 100%, 7% 100%, 0 49%);
    background: #F2FAF9;
    border: 1px solid #009688;
  }
  .txt-accompagner-bakeli{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #4F4F4F;
    align-items: center;
    text-align: center;
    padding-top: 5%;
  }
  .txt-accompagner-bakeli:hover{
      color: #009688;
  }
  .txt-center-acc {
      text-align: center;
      display: block;
  }
  .boder-component{
    border: 1px solid #009688;
    box-sizing: border-box;
  }
  .btn-rejoint-aventure-leading{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #009688;
    width: 350px;
    height: 60px;
    border: 2px solid #009688;
    border-radius: 10px;
    background: #fff;
  }
  .btn-rejoint-aventure-leading:hover{
    color: #fff;
    background: #009688;
  }
  
  @media only screen and (max-width: 960px){
    .btn-rejoint-aventure-leading{
      width: 170px;
    height: 60px;
    margin-bottom: 5px;
    }
}