
.component-content-photo{
    
}
.imphoto {
    width: 255px;
    height: 284px;
}
.bgphoto {
    background: rgba(255, 255, 255, 0.9);
    box-shadow: inset 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}
.ReactGridGallery{
    display: inline-flexbox;
    flex-direction: column;
    width: 100%;
    background: rgba(255, 255, 255, 0.9);
    /* background: orange; */
}
.ReactGridGallery>.ReactGridGallery_tile{
    /* width: 350px; */
    /* border: 1px solid red; */
    float: left !important;
}
.ReactGridGallery_tile-viewport {
    width: 350px;
    height: 180px;
    overflow: hidden;
}
.image-responsive {
    cursor: pointer;
    width: 350px;
    height: 180px;
    margin-left: -4px;
    margin-top: 0px;
}
.breadcrumb {
    background-color: rgb(240 248 255) !important;
}
.lien_nav {
    font-family:' Montserrat' !important;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: rgba(0, 150, 136, 0.8) !important;
    
}
.lien_nav:hover {
    list-style: none;
    text-decoration: none;
    

color: rgba(0, 150, 136, 0.8);
}
.lien_nav_current {
    font-family: 'Montserrat' !important;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #069A77;
    padding-left: 1rem;
}

@media screen and (max-width: 1000px) and (min-width: 1400px)
{
    .photos
    {
        width: auto;
        flex: none;
        max-width: 100%;
        padding: 1rem;
        margin-left: 1rem;
        margin-right: 0.5rem;
    }
}