
.component-footer{
    background: #042D29;  
    font-size: 1.2rem;
}
.text{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
}
.taille-logo-bakeli-footer{
    width: 4%;
    height: 20%;
}
.forme-ecrit{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
}
.txt-des-bakeli{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
}
.liens{
    font-weight: bold;
}
.grid-reseaux{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-right: 10px;
    margin-left: -11px;
}
.annulation-de-la-marge{
    padding-left: 100px;
    padding-right: 100px;
}
@media screen and (max-width: 868px){
    .annulation-de-la-marge{
        padding-left: 0px;
        padding-right: 0px;
    }

}
@media screen and (min-width: 870px) and (max-width: 991px){
    .grid-reseaux{
        margin-right: 369px;
        margin-left: -110px;
        margin-right: 410px;
    }
 .resp-suivre{
     margin-left: -12%;
 }
}