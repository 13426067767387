.bg-image-aventure {
  background-image: url(../../img/HomeImage.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 110vh;
  background-position: center;
  background-attachment: scroll;
  background-origin: content-box;
  width: auto;
}
.rejoigner-aventure {
  padding-top: 11%;
  padding-left: 4%;
}
.txt-rejoint-aventure {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 54px;
  line-height: 72px;
  color: #1c1e52;
}
.btn-commencer-aventure {
  background: #ff9800;
  border-radius: 112.5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #f9faff;
  width: 169px;
  height: 45px;
}
.btn-commencer-aventure:hover {
  background: #009688;
  color: #fff;
}
.btn-commencer-aventure:hover {
  background: #009688;
  color: #fff;
}
@media only screen and (max-width: 960px) {
  .btn-commencer-aventure {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    width: 120px;
    height: 45px;
  }
  .txt-rejoint-aventure {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 44px;
    line-height: 42px;
    color: #1c1e52;
  }
}
