.component-candidature-form {
}
.logo-bakeli {
  width: 115px;
  margin-bottom: 38px;
}
.imgR{
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  padding: 1rem 3 1rem;
  /* max-width: 16.666667%; */
  width: 630px;
  /* margin-left: -1em; */
  height: auto;
}
.container-nav-vertical-homeI {
  /* Your non-responsive styles here */

  /* Set a background image */
  background: url(../../img/fevrier.jpeg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;

  /* Fixed position for large screens */
  position: fixed;
  padding: 1rem 3rem 1rem 1rem; /* You might want to check if this padding is defined correctly */
  width: 50%;
  height: 100%;
}
.container-nav-vertical-homeII {
  /* Your non-responsive styles here */

  /* Set a background image */
  background: url(../../img/Nov1.jpeg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;

  /* Fixed position for large screens */
  position: fixed;
  padding: 1rem 3rem 1rem 1rem; /* You might want to check if this padding is defined correctly */
  width: 50%;
  height: 100%;
}
/* Media Query for medium-sized screens (e.g., tablets) */
@media (max-width: 768px) {
  .container-nav-vertical-home {
    position: static; /* Remove fixed positioning */
    height: auto; /* Allow the content to determine the height */
    background: none; /* Remove the background image */
    padding: 1rem; /* Adjust padding as needed */
  }
}


.control-3 {
  /* height: 65px; */
  width: 100%;
  background: #ffffff;
  border: 0.1px solid #03a192;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 12px;
  outline: none;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1), 0 7px 20px 0 rgba(0, 0, 0, 0.1);
}

.motivation-candidature {
  font-size: 15px;
  font-weight: 200;
  border: none;
  height: 100px;
  width: 100%;
  background: #ffffff;
  border: 0.1px solid #03a192;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 14px;
  padding: 10px;
  outline: none;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1), 0 7px 20px 0 rgba(0, 0, 0, 0.1);
}

.checkbox {
  margin: 20px;
}

.checkbox label {
  min-height: 21px;
  margin-bottom: 0;
  font-weight: normal;
  color: #757575;

  cursor: pointer;
  line-height: 22px;
}

.c-checkbox span {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-left: -20px;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: 1px solid #ccc;
  text-align: center;
}

.c-checkbox span:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  text-align: center !important;
  font-size: 12px;
  line-height: 18px;
  vertical-align: middle;
}

.c-checkbox input[type="radio"] {
  position: absolute;
  margin: 4px 0 0;
  opacity: 0;
}

.c-checkbox input[type="radio"]:checked {
  color: #fff;
  transition: color 0.3s ease-out;
}

.c-checkbox input[type="radio"]:checked + span {
  border-color: #009688;
  background-color: #009688;
}

.c-checkbox input[type="radio"]:checked + span:before {
  color: #fff;
  opacity: 1;
  transition: color 0.3s ease-out;
}
.btn-theme-candidature {
  background: #069a77 !important;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 13px;
  letter-spacing: 0.08em;
  color: #ffffff !important;
  padding: 0.8rem;
  transition: 0.2s ease-out;
  border: 1px solid #069a77 !important;
}

.btn-theme-candidature:hover {
  background: #ffff !important;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 13px;
  letter-spacing: 0.08em;
  color: #069a77 !important;
  padding: 0.8rem;
  transition: 0.2s ease-out;
  border: 1px solid #069a77 !important;
}
p.successMsg {
  color: black;
  font-size: 15px;
  height: 75vh;
}

/* Add this CSS code to your stylesheet */

/* For screens smaller than medium (e.g., mobile devices) */
@media (max-width: 768px) {
  .banner-connexion-page-home {
    flex-direction: column-reverse;
  }

  .imgR {
    width: 100%;
    height: auto;
  }
}

/* For medium-sized screens and larger (e.g., tablets and desktops) */
@media (min-width: 769px) {
  .imgR {
    max-width: 100%; /* Ensure the image doesn't exceed its container width */
    height: auto;
  }
}
