* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat";
}
.page-register {
  width: 100%;
}
.component-inscription {
  text-align: center;
}
.section-left {
  background-image: linear-gradient(
      rgba(6, 154, 119, 0.8),
      rgba(6, 154, 119, 0.8)
    ),
    url("../../img/bg-left.png");
  background-attachment: fixed;
  background-size: cover;
  color: #fff;
  opacity: 70%;
  background-color: #009688;
  opacity: 5;
  height: 100vh;
}
#section-right {
  background: #ffffff;
  height: 100vh;
}
.logo-bakeli {
  width: 80px;
  height: 50px;
  text-align: center;
}
.deplacement-bloc {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 8rem;
}
.title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 104px;
  color: #ffffff;
  justify-content: center;
}
.card-text {
  font-family: "Montserrat";
  font-style: normal;
  /* font-weight: bold; */
  font-size: 14px;

  /* line-height: 102%; */
  /* color: #ffffff; */
}
.deplacement-bloc-2 {
  padding-top: 2rem;
  padding-right: 2rem;
  padding-left: 1rem;
}
.title-inscription {
  color: #009688;
  font-weight: bold;
  font-size: 28px;
  line-height: 14px;
  letter-spacing: 0.1em;
}
.control-2 {
  height: 53px;
  width: 100%;
  background: #ffffff;
  border: 0.1px solid #03a192;
  box-sizing: border-box;
  border-radius: 10px;
  outline: none;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1), 0 7px 20px 0 rgba(0, 0, 0, 0.1);
}
.select-row {
  margin-right: 0 !important;
}

/* .row{
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 0px; 
    margin-left: 0px;
} */
input[type="email"],
input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"] {
  margin-bottom: 15px;
}
input[placeholder="Prénom"],
input[placeholder="Adresse"],
input[placeholder="Adresse e-mail"],
input[placeholder="Nom"],
input[placeholder="Numero de Téléphone"],
input[placeholder="Lieu de Naissance"],
input[placeholder="Mot de passe"] {
  padding: 1rem;
}

.form-select {
  margin-bottom: 15px;
}
/* .btn-primary{
    background-color: #009680;
    border-color: #009688;
} */
.btn-inscription {
  width: 50%;
  height: 65px;
  color: #fff;
  background: #009688;
  border-radius: 10px;
  text-decoration: none;
  border: none;
  outline: none;
  padding: 1rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
}
.btn-inscription:hover {
  background: #009688;
  color: #fff;
}
.b-inscription {
  text-align: center;
}
.ou {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.51);
  margin-top: 2rem;
}
.logo-google {
  width: 30px;
  height: 30px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1), 0 7px 20px 0 rgba(0, 0, 0, 0.1);
}
.bordure-hr {
  border-bottom: 1px solid #009688;
}
.logo-facebook {
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
  /* margin-left: 20px; */
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1), 0 7px 20px 0 rgba(0, 0, 0, 0.1);
}
.texte-ici {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 34px;
  letter-spacing: 0.1em;
  text-decoration: none;
  color: #009688;
}
.marge-logo {
  padding-top: 3.2rem;
}

.texte-ici:hover {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 34px;
  letter-spacing: 0.1em;
  text-decoration: none;
  color: #009688;
}

/* .bloc-logo-inscription{
    position: absolute;
    background: #fff;
    left: 40%;
    top: 90%;
    width: 150px;
    
} */

@media screen and (max-width: 767px) {
  .blok_image_logo {
    width: 50%;
    padding: 0;
    margin: 0;
  }
  .logo-google {
    margin-left: 3rem;
  }
  .logo-facebook {
    margin-right: 3rem;
  }
  .ou {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.51);
    margin-top: 2rem;
  }
  .texte_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.51);
  }
}

@media screen and (min-width: 320px) and (max-width: 568px) {
  .center-btn-con {
    margin-left: 0;
  }

  .bloc-logo-inscription {
    position: absolute;
    background: #fff;
    margin-left: -8%;
    top: 164%;
    width: 150px;
  }

  /* .btn-inscription {
    margin-left: 10%;
  } */
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .bloc-logo-inscription {
    position: absolute;
    background: #fff;
    margin-left: -5%;
    top: 54%;
    width: 150px;
  }
}
@media screen and (min-width: 414px) and (max-width: 736px) {
  .center-btn-con {
    margin-left: 0;
  }

  .bloc-logo-inscription {
    position: absolute;
    background: #fff;
    margin-left: -5%;
    top: 126%;
    width: 150px;
  }
  .ou {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.51);
    margin-top: 2rem;
  }
  /* .btn-inscription {
    margin-left: 15%;
  } */
}
@media screen and (max-width: 812px) {
  .center-btn-con {
    margin-left: 0;
  }

  /* .bloc-logo-inscription{
        position: absolute;
        background: #fff;
        margin-left: -5%;
        top: 114%;
        width: 150px;
    } */
  .btn-inscription {
    width: 100%;
  }
}
/* @media screen and (max-width: 1270px){
    .center-btn-con {
        margin-left: 100;
    }
    .bloc-logo-inscription{
        position: absolute;
        background: #fff;
        margin-left: -5%;
        width: 150px;
        
    }
} */
