.formations-card-bottom{
    margin-bottom: 15px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1), 0 7px 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    cursor: pointer;
}
#formation-card-title{
    color: #009688;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
}
.page-form {
    padding-right: 30px !important;
   
}