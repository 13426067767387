
.component-content-reservation{
    
}
.btn-rouge {
    background: rgba(244, 67, 54, 0.4);
    box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 173px;
    height: 40px;
    font-weight: bold;
    color: red;
}
.btn-rouge:hover{
    color: #FFFFFF;
}
.btn-chekk {
    background: #FFFFFF;
    /* vert */

    border: 1px solid #009688;
    box-sizing: border-box;
    border-radius: 5px;
    color: #009688;
    line-height: 2rem;
    float: right;
    width: 152px;
    height: 40px;
}
.btn-chekk:focus {
    background-color:  #009688;
    color: white
}

.bloctextt {
    display: inline-block;
}

.bloctexte {
    display: inline-block;
}
.texteblokkk {
    display: inline-block;
}
.texte1 {
    font-weight: bold;
}
