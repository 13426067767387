.component-content-event-avenir {
}

.im {
  width: 71px;
  height: 73px;
}
.center {
  text-align: center;
  justify-content: center;
  color: #009688;
  line-height: 2.2rem;
}
.journee {
  font-size: 0.8rem;
}
.buttonphoto {
  display: flex;
}

.btn-inscri {
  font-family: "Montserrat", sans-serif;
  color: white;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 1rem;
  font-weight: bold;
  font-style: normal;
  font-size: 11px;
  background: #009688;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.btn-inscri:hover {
  font-family: "Montserrat", sans-serif;
  color: #009688;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 1rem;
  font-weight: bold;
  font-style: normal;
  font-size: 11px;
  background: white;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

/* @media screen and (max-width: 1200) and (min-width: 1300) {
    .btn-inscri {
        font-size: 0.6rem;
        padding: 1rem;
        width: auto;
        max-width: 100%;
        flex-direction: column;
    }
} */
