.activite-1{
    background-color: #fff;
}
.date-horaires{
    color:#009688;
    border-right: 3px solid #009688;
}
.articles-bottom{
    margin-bottom: 15px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1), 0 7px 10px 0 rgba(0, 0, 0, 0.1);
}
.proprite-folder{
   cursor: pointer;
   
}
#folder-txt > span{
    position: absolute;
    top: 50%;
    left: 25%;
}
.position-dossier{
    position: relative;
}
.pos-img1{
    position: absolute;
    cursor: pointer;
    left: 50%;
    top: 22%;
}