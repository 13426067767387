.texte-inscri {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.51);
}
.logo-login {
  filter: drop-shadow(4px 0px 8px rgba(0, 0, 0, 0.25));
}
.link-connexion {
  color: #009688;
  text-decoration: none;
}

.banner-connexion-page {
  width: 100%;
  background: linear-gradient(
      90deg,
      rgba(6, 154, 119, 0.7962535355939251) 35%,
      rgba(6, 154, 119, 0.8018557764902836) 100%
    ),
    url(../../img/banner1.jpg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  /* padding: 4rem 8rem; */
}
.logo-connexion {
  width: 115px;
  margin-bottom: 60px;
}
.titre-banner-connexion {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 88px;
  line-height: 99.5%;
  color: #ffffff;
}
.slogan-banner-connexion {
  font-style: normal;
  font-weight: 700;
  /* font-size: 19px;
  line-height: 102%; */
  font-size: 20px;
  line-height: 162%;
  letter-spacing: 0.12em;
  color: #ffffff;
}
.btn-connexion {
  background: #069a77;
  border-radius: 10px;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1em;
  color: #ffffff;
  padding: 15px;
  border: 1px solid #069a77;
  transition: 0.2s ease-in-out;
}
.btn-connexion:hover {
  background-color: #fff;
  color: #069a77;
  transition: 0.2s ease-in-out;
}

.btn-connexion-facebook:hover {
  background: rgb(83, 153, 245);
  color: #ffffff;
}
.btn-connexion-facebook {
  border-radius: 10px;
  /* width: 100%; */
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  padding: 10px;
  transition: 0.2s ease-in-out;
  font-size: 25px;
  line-height: 17px;
  letter-spacing: 0.1em;
  background-color: #fff;
  color: rgb(83, 153, 245);
  border: 1px solid rgb(83, 153, 245);

  transition: 0.2s ease-in-out;
}
.btn-connexion-google:hover {
  background: rgb(218, 50, 50);

  color: #ffffff;
}
.btn-connexion-google {
  border-radius: 10px;
  /* width: 100%; */
  padding: 10px;
  transition: 0.2s ease-in-out;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 17px;
  letter-spacing: 0.1em;
  background-color: #fff;
  color: rgb(218, 50, 50);
  border: 1px solid rgb(218, 50, 50);

  transition: 0.2s ease-in-out;
}
.mdp-forget-link {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 15px;
  color: #069a77;
  transition: 0.2s ease-in-out;
  margin-bottom: 30px;
  text-align: right;
  display: block;
}
.mdp-forget-link:hover {
  transition: 0.2s ease-in-out;
  color: #069a77;
}
.h-connexion-form {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.1em;
  color: #069a77;
  margin-bottom: 50px;
  text-align: center;
}
.icon-form-connexion {
  width: 15px;
}

.input-form-connexion {
  border-top: 1px solid #069a77;
  border-left: 0px;
  border-right: 1px solid #069a77;
  border-bottom: 1px solid #069a77;
  border-radius: 10px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.51);
  height: 50px;
}
.input-form-connexion:focus {
  border-top: 1px solid #069a77;
  border-left: 0px;
  border-right: 1px solid #069a77;
  border-bottom: 1px solid #069a77;
}
.input-group-text-connexion {
  background: transparent;
  color: #069a77;
  border: 1px solid #069a77;
  border-radius: 10px;
  height: 50px;
  /* height: 65px; */
}
.loader {
  color: #069a77;
  background-color: #069a77;
  -webkit-box-shadow: 0 0.125rem 0.8rem rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 0.125rem 0.8rem rgba(0, 0, 0, 0.1) !important;
}

/* Social Login */

.omb_loginOr {
  position: relative;
  font-size: 1em;
  color: #aaa;
  margin-top: 1em;
  margin-bottom: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.omb_loginOr .omb_hrOr {
  background-color: #cdcdcd;
  height: 1px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.kep-login-facebook {
  font-family: Helvetica,sans-serif;
  font-weight: 600;
  text-align: left;
  text-transform: none !important;
  -webkit-font-smoothing: antialiased;
  color:  #fff !important;
  cursor: pointer;
  display: inline-block;
  font-size: none;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color .3s,border-color .3s;
  background-color: #3b5998 !important;
  border: calc(.06887vw + .67769px) solid #fff !important;
  padding: none;
  padding: calc(.34435vw + 7.38843px) calc(.34435vw + 8.38843px) !important;
  border-radius: 50% !important;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1), 0 7px 20px 0 rgba(0, 0, 0, 0.1);

}
.omb_loginOr .omb_spanOr {
  display: block;
  position: absolute;
  left: 37%;
  top: -0.6em;
  margin-left: -1.5em;
  background-color: white;
  width: 10em;
  text-align: center;
}
.kep-login-facebook.metro {
  height: 50px;
  width: 100%;
  border-radius: 10px !important;  
}
.kep-login-facebook.metro:hover{
  border: 2px solid #3b5998 !important;
  background-color: #fff !important;
  color: #3b5998 !important;
}
/* .alignemant-icon-fb-block{
  display: list-item;
} */
.but-goog{
  height: 50px;
  width: 100%;
  border-radius: 10px !important;
  background-color: #fff !important;
  color: #000 !important;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1), 0 7px 20px 0 rgba(0, 0, 0, 0.1) !important;
}
.but-goog:hover{
  border: 2px solid rgb(218, 50, 50) !important;
  color: rgb(218, 50, 50) !important;
  font-weight: bold;
}
/* .icon-facebook-connexion {
  width: 30px;
} */

.auth-error.text-center {
  color: red;
  padding: 0.5em;
}

.logo-google-login {
  width: 30px;
  height: 30px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1), 0 7px 20px 0 rgba(0, 0, 0, 0.1);
}

/* .logo-facebook-login {
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
   margin-left: 20px; 
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1), 0 7px 20px 0 rgba(0, 0, 0, 0.1);
} */
.marge-bottom {
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .blok_image_logo_login {
    width: 50%;
  }
  .btn-connexion {
    margin-bottom: 5px;
  }
  .logo-google-login {
    margin-left: 6rem;
    margin-top: 8px;
  }
  /* .logo-facebook-login {
    margin-right: 1rem;
  } */
  .ou {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.51);
    margin-top: 2rem;
  }
  .texte_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.51);
  }
  .mdp-forget-link {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 15px;
    color: #069a77;
    transition: 0.2s ease-in-out;
    margin-bottom: 30px;
    text-align: right;
    display: block;
  }
}

@media screen and (min-width: 320px) and (max-width: 568px) {
  .center-btn-con {
    margin-left: 0;
  }
  .bloc-logo-connexion {
    position: absolute;
    background: #ffffff;
    margin-left: 32%;
    margin-top: -10%;
    width: 150px;
  }
  .btn-connexion {
    width: 100%;
  }
}
@media screen and (min-width: 414px) and (max-width: 736px) {
  .center-btn-con {
    margin-left: 0;
  }
  .btn-connexion {
    width: 100%;
  }
  .bloc-logo-connexion {
    position: absolute;
    background: #fff;
    margin-left: 32%;
    top: 82%;
    width: 150px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .center-btn-con {
    margin-left: 0;
  }
  .btn-connexion {
    width: 100%;
  }
  .bloc-logo-connexion {
    position: absolute;
    background: #fff;
    margin-left: 30%;
    top: 55%;
    width: 150px;
  }
}
