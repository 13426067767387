
.component-navbar-network-leading{
    
}
.taille-logo-bakeli-navigation{
    width: 70px;
    height: 40px;
    margin-left: 3%; 
    cursor: pointer;  
}
.button__shadow {
    position: absolute;
    border-radius: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: #af9d9d;
    animation: shadow 2s infinite ease-in-out;
    z-index: -1;
  }
  @-moz-keyframes shadow {
    0%, 100% {
      transform: scaleX(1);
      opacity: 1;
    }
    50% {
      opacity: 0.2;
      transform: scaleX(0.25);
    }
  }
  @-webkit-keyframes shadow {
    0%, 100% {
      transform: scaleX(1);
      opacity: 1;
    }
    50% {
      opacity: 0.2;
      transform: scaleX(0.25);
    }
  }
  @-o-keyframes shadow {
    0%, 100% {
      transform: scaleX(1);
      opacity: 1;
    }
    50% {
      opacity: 0.2;
      transform: scaleX(0.25);
    }
  }
  @keyframes shadow {
    0%, 100% {
      transform: scaleX(1);
      opacity: 1;
    }
    50% {
      opacity: 0.2;
      transform: scaleX(0.25);
    }
  }
  @-moz-keyframes flow-and-shake {
    0%, 100% {
      transform: translate(calc(var(--x) * -1%), 0) rotate(calc(var(--rotation) * -1deg));
    }
    50% {
      transform: translate(calc(var(--x) * 1%), calc(var(--y) * 1%)) rotate(calc(var(--rotation) * 1deg));
    }
  }
  @-webkit-keyframes flow-and-shake {
    0%, 100% {
      transform: translate(calc(var(--x) * -1%), 0) rotate(calc(var(--rotation) * -1deg));
    }
    50% {
      transform: translate(calc(var(--x) * 1%), calc(var(--y) * 1%)) rotate(calc(var(--rotation) * 1deg));
    }
  }
  @-o-keyframes flow-and-shake {
    0%, 100% {
      transform: translate(calc(var(--x) * -1%), 0) rotate(calc(var(--rotation) * -1deg));
    }
    50% {
      transform: translate(calc(var(--x) * 1%), calc(var(--y) * 1%)) rotate(calc(var(--rotation) * 1deg));
    }
  }
  @keyframes flow-and-shake {
    0%, 100% {
      transform: translate(calc(var(--x) * -1%), 0) rotate(calc(var(--rotation) * -1deg));
    }
    50% {
      transform: translate(calc(var(--x) * 1%), calc(var(--y) * 1%)) rotate(calc(var(--rotation) * 1deg));
    }
  }

  .button__wrap {
    position: relative;
  }
.btn-theme-gradient {
    /* background: linear-gradient(#0096876e, orange); */
    background: linear-gradient(-45deg, #009688, #f65347, #ff9800, #ff9800);
    background-size: 400% 400%;
    animation: gradient 5s ease infinite;
    border-radius: 10px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 13px;
    letter-spacing: 0.08em;
    color: #ffffff !important;
    padding: 0.8rem;
    transition: 0.2s ease-out;
    border: 1px solid #069a77 !important;
  }
  .btn-theme-gradient:hover {
    color: #069a77 !important;
    background: linear-gradient(#ffff, orange);
    transition: 0.2s ease-in;
  }
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
.bg-navbar{
    /* height: 4rem; */
    background: #fff;
    position: fixed;
    width: 100%;
    box-shadow: 2.86116px 2.86116px 7.4446px rgba(0, 0, 0, 0.1);   
}
/* .disp-accueil{
    padding-top: 10px;
  } */
  .position-item-navbar{
      padding-right: 10px;
  }
  .position-item{
      padding-right: 20px;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.7);
  }
  .position-item a{
      color: #000;
  }
  .position-item a:hover{
   text-decoration: none;
   outline: none;
}
.con-color a:hover{
    color: #009688;
}
.inscrip-color a:hover{
    color: #FF9800;
}
.padd-right-dep{
    padding-right: 40%;
}
.menu-area{
    position: static;
}
.mega-area{
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    padding: 15px;
}
.dropdown-item{
    padding: 3px 0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 27px;
    color: rgba(0, 0, 0, 0.7)
}
.dropdown-toggle:hover{
    background: none;
}
.formation-color-txt{
    color: #000;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
}
.formation-color-txt:hover{
    text-decoration: none;
    color: #000;
}
.formation-color-txt:focus{
    text-decoration: none;
    color: #000;
}
.txt-menu-drop-lead{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.7);
    font-weight: bold;
}
.txt-menu-drop-lead:hover{
    color: #009688;
}
.navbar-nav .nav-item .nav-link{
    font-weight: 600;
}
 Link:hover{
    text-decoration: none;
    outline: none;
    color: #009688;
}
@keyframes slideInFromTop {
    0% {
      transform: translateY(-100%);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  .mega-area{
    animation: slideInFromTop .2s ease-in;
}

#check{
	display: none;
}
label #sidebar_btn{
    /* z-index: 1; */
    margin-top: 0px;
    margin-left: -4%;
    cursor: pointer; 
    /* margin-right: 50px; */
}
#check:checked ~.position-item-navbar{
    right: 10px;
    display: block;
    width: 500px;
    /* background-image: linear-gradient(rgb(0, 0, 0, .9), rgb(0, 0, 0, .9)); */
}
.position-item-navbar a{
    display: block;
    color: #000;
    line-height: 35px;
    text-decoration: none;
    font-size: 1.1rem;
    padding-bottom: 15px;
}
#sidebar_btn{
    display: none;
	cursor: pointer;
}
/* .dep-icon-menu{
    margin-right: 7%;
    position: fixed;
} */
@media only screen and (max-width: 960px){
    .taille-logo-bakeli-navigation{
        width: 50px;
        height: 30px;
        margin-left: 3%; 
        cursor: pointer;  
    }
    .position-item-navbar{
        display: none;
    }
    #sidebar_btn{
        display: block;
        cursor: pointer;
        position: fixed;
    }
    #check:checked ~.position-item-navbar{
        width: 300px;
        left: 10%;
    }
    .position-item-navbar a{
        font-size: 1rem;
        padding-bottom: 5px;
    }
    .formation-color-txt{
        font-size: 16px;
        line-height: 20px;
    }
      .dropdown-menu{
        min-width: 30rem !important;
      }  
}
@media only screen and (max-width: 768px){
    .dropdown-menu{
        min-width: 25rem !important;
      } 
      .formation-color-txt{
        font-size: 12px;
        line-height: 12px;
    }
    .taille-logo-bakeli-navigation{
        width: 30px;
        height: 20px;
        margin-left: 1%; 
        cursor: pointer;  
    }
}
@media only screen and (max-width: 560px){
    .dropdown-menu{
        min-width: 15rem !important;
      } 
      .taille-logo-bakeli-navigation{
        width: 30px;
        height: 20px;
        margin-left: 1%; 
        cursor: pointer;  
    }
}