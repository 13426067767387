
.component-content{
    
}
.article {
    color: #009688;
    font-size:1rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}

.bordure {
    border: 1px #009688 solid;
    border-radius: 1rem;
}
.comm {
    display: inline-block;
}
.imgg {
    width: 50px;
    height: 50px;
    margin-left: 40px;
    margin-top: 24px;
}
.cv {
    width: 110px;
    height: 41px;
    margin-left: 41px;
}
.chiffre {
    color: gray;
    margin-left: 1rem;
}
.nom {
    margin-left: 2rem;
    font-size: 0.8rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    
}
.heure {
    margin-left: 2rem;
    font-size: 0.7rem;
    font-family: 'Montserrat', sans-serif;
}
#lien {
    margin-bottom: 3rem;
    color: #11A7D7;
}
.cv1 {
    width: 240px;
    height: 174px;
}

.cv2 {
    width: 240px;
    height: 174px;
}
.cv3 {
    width: 100px;
    height: 174px;
}
.scroll {
    overflow: scroll;
    height: 100vh;
}