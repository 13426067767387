.postuler-button-card-center{
    text-align: center;
}
#txt-designer-ui{
    text-align: center;
}
#Postuler-buton{
    width: 25%;
    height: 45px;
    background: #009688;
    border-radius: 10px;
    text-decoration: none;
    border: none;
    outline: none;
    margin-top: 20px;
}
.offre-stage-card-bordure-non{
    background: rgba(196, 196, 196, 0.05);
    border: none;
}
.btom-card-offre-stage-bord{
    border: 0.1em solid #009688;
}