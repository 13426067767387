.component-inscription-rencontre {
}

.container-nav-vertical-home-jeema {
  /* background: #009688; */

  /* width: 100%; */
  position: fixed;
  background: linear-gradient(
      90deg,
      rgba(8, 83, 78, 0.42) 35%,
      rgba(0, 150, 136, 0.08) 100%
    );
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1rem 3 1rem;
  width: 720px;
  margin-left: -1em; 
  height: 700px;
}

.imgA{
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  /* padding: 1rem 3 1rem; */
  /* max-width: 16.666667%; */
  width: 700px;
  /* margin-left: -1em; */
  height: 615px;
}


