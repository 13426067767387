.en-plus-large-buton{
    width: 100%;
    height: 8vh;
    margin-bottom: 5vh;
    border-radius: 0;
    color: #009688;
    background: #fff;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1), 0 7px 10px 0 rgba(0, 0, 0, 0.1);
}
.en-plus-large-buton:hover{
    background: #009688;
    border: none;
}
.fa-eye{
    color: blue;
}
.tof-pad-left{
    padding-right: 0px;
    padding-left: 0px;
}
.mid-card-left{
    margin-left: 15%;
}
#bom-space{
    margin-top: 25px;
}
.page-annonce {
    padding-right: 15px !important;
}