.p-b-200{
    margin: 8px;
}
.btn-ajouter-formation{
    width: 314px;
    height: 45px;
    background: #009688;
    border-radius: 5px;
    text-decoration: none;
    border: none;
    outline: none;
    margin-top: 20px;
}
.btom-card-demande-candidature{
    margin-bottom: 15px;
}
.btn-ajouter-formation:hover{
    background: #009688;
}
.images-aita-card-center{
    text-align: center;
}
.images-aita-card-center img{
    width: 100px;
    height: 100px;
}
.images-aita-card-center h2{
    color: #009688;
}
.experience-demande-candidature{
    color: #009688;
    font-weight: bold;
    
}
.select-day-demande-candidature{
    width: 100%;
    padding-left: 0px;
    margin-left: 0px;
    padding-right: 0px;
    margin-right: 0px;
    height: 40px;
    background: #FFFFFF;
    border: none;
    outline: none;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1), 0 7px 20px 0 rgba(0, 0, 0, 0.1);
}
.text-et-buton-check{
    margin-left: 40%;
}
#annuller-buton-demande{
    width: 80%;
    height: 55px;
    background: #009688;
    border-radius: 5px;
    text-decoration: none;
    border: none;
    outline: none;
    margin-top: 20px;
}
.img-cv-demande-candidature{
    width: 100px;
    height: 50px;
}
.check-box-choix-formations{
    background: #ffffff;
    border: none;
    outline: none;
    box-sizing: border-box;
    height: 25px;
    border-radius: 10px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1), 0 7px 20px 0 rgba(0, 0, 0, 0.1);
}
.form{
    font-size: 15px;
    height: 35px;
    width: 172px;
    color: black;
    border-top: none;
    border-left: none;
    border-right: none;
}
form h6{
    font-size: 12px;
}
.label{
    display: inline-block;
    margin-bottom: 1rem;
    font-weight: bold;
}
.experience{
    margin-top: 25px;
}
.btn-theme {
    background: #069a77 !important;
    border-radius: 10px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.08em;
    color: #ffffff !important;
    padding: 0.8rem;
    transition: 0.2s ease-out;
    border: 1px solid #069a77 !important;   
    width: 82%;
  }
  .motivation {
    font-size: 14px;
    font-weight: 400;
    border: 1px solid black;
    border-radius: 8px;
    width: -webkit-fill-available;
    height: 100px;
    color: black !important;
  }
  .spanStyle {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
  }
  .radio{
    width : 30px;
  }
  .errorMsg{
      color: #f10909ef
  }
  .choix {
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 400;
    margin-left: 5%;
    padding: 2%;
  }
input[type=checkbox] { border: 0px; height: 1.3em; } 
input[type=radio] { border: 0px; height: 0.9em;} 