
.component-card-stage{
    
}

.btn-plus {
    font-family: 'Inter';
    border: 1px solid #f3f3f3;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 1rem;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.75) !important;
}
.btn-plus:hover {
    
    text-decoration: none;
} 
.stagetitre {
    text-align: center;
}