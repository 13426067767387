
.component-viewnetwork-leading{
    
}
.avent-bak{
    padding-top: 4%;
}
.avent-bak-aventure{
    padding-top: 1%;
}
.theme-body{
    background: #F2FAF9;
}